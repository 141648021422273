@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
body {
  font-family: "Roboto", sans-serif !important;
}
.app {
  display: flex;
  flex-direction: column;
  gap:46px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;

  font-style: normal;
}
h2 {
  margin-bottom: 36px;
}
.projects-section {
  padding: 20px;
}

.projects-left {
  margin-left: 30px;
  display: flex;
  align-items: center;
}

.projects-left h1 {
  width: 100%;
  text-align: center;
}

.projects-right {
  display: flex;
  justify-content: center;
  gap: 100px;
  flex-wrap: wrap;
}
.projects-right img {
  max-width: 300px;
  max-height: 300px;
  object-fit: fill;
  width: 300px;
}

.sparepart-section {
  padding: 20px;
}

.sparepart-left {
  margin-left: 30px;
  display: flex;
  align-items: center;
}

.sparepart-left h1 {
  width: 100%;
  text-align: center;
}

.sparepart-right {
  display: flex;
  justify-content: center;
  gap: 100px;
  flex-wrap: wrap;
}
.sparepart-right img {
  max-width: 300px;
  max-height: 300px;
  object-fit: fill;
  width: 300px;
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 115px;
  height: 600px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.banner-content {
  text-align: center;
  padding: 0 100px;
  color: white;
}
.banner-content h1 {
  font-size: 48px;
}
.banner-content h1,
.banner-content p {
  width: 100%;

  transition: opacity 1s ease;
}
.banner-content p {
  font-size: 32px;
}

.invisible {
  opacity: 0;
}

.visible {
  opacity: 1;
}

.banner-button {
  border: none;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.about-section {
  display: flex;
  justify-content: space-around;
  padding: 16px;
}

.about-content {
  display: flex;
  border-left: 5px solid #0056b3;
  align-items: center;
  max-width: 1000px;
  box-shadow: 5px 4px 8px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 30px;
}

.about-content p {
  line-height: 1.6;
}

.about-logo {
  border-radius: 10px;
  width: 350px;
}

.services-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px;
}

.services-card {
  width: 400px;
  text-align: center;
  padding: 0 20px;
  box-shadow: 5px 4px 8px 5px rgba(0, 0, 0, 0.1);
}

.services-left {
  margin-left: 30px;
  display: flex;
  align-items: center;
}

.services-left h2 {
  width: 100%;
  text-align: center;
}

.services-right {
  display: flex;
  justify-content: center;
  gap: 100px;
  flex-wrap: wrap;
}
.contact-section {
  padding: 16px;
  display: flex;
  margin: 16px 0;

  justify-content: space-around;
}
.contact-left p {
  margin-bottom: 26px;
}
.contact-right {
  box-shadow: 5px 4px 8px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.contact-icon {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.contact-head {
  display: flex;
  gap: 16px;
}
.contact-form input {
  padding: 10px;
  width: 100%;
  font-size: 16px;
  resize: none;
}
.whatsapp-link {
  position: relative;
  display: inline-block;
}

.whatsapp-link img {
  display: block;
}

.flags {
  position: absolute;
  top: -15px;
  left: 0;
  display: flex;
}

.flags img {
  width: 20px;
  height: 20px;
  margin-left: 2px;
}
.email-link {
  position: relative;
  display: inline-block;
}

.email-link img {
  display: block;
}

.notification {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  padding: 2px 6px;
  font-size: 12px;
  border-radius: 50%;
}

.contact-form textarea {
  width: 100%;
  padding: 0;
  font-size: 16px;
  resize: none;
}
.send-button {
  display: inline-block;
  background-color: #25d366;
  color: white;
  width: 150px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-weight: bold;
}

@media (max-width: 1025px) {
  .contact-section {
    flex-direction: column;
    padding: 12px;
  }
}
@media (max-width: 768px) {
  .app {
    width: 100%;
  }
  .banner-content {
    padding: 0;
  }
  .banner-content p {
    font-size: 24px;
  }
  .banner-content h1 {
    font-size: 36px;
  }

  .services-card {
    width: 100%;
    text-align: center;
  }
  .projects-right {
    gap: 16px;
  }
  .services-right {
    gap: 16px;
  }
  .about-section {
    flex-direction: column;
  }
  .about-logo {
    object-fit: fill;
    width: 100%;
    height: 300px;
  }
  .about-content {
    margin-bottom: 20px;
  }

  .contact-section {
    flex-direction: column;
    padding: 12px;
  }

  .contact-head {
    flex-direction: column;
  }
  .contact-left {
    padding: 16px;
  }
  .contact-form input {
    padding: 10px 0;
  }
}
