.navbar {
  color: black;
  display: flex;
  position: fixed;
  width: 100%;
  justify-content: space-around;
  background-color: white;
  z-index: 999;
  align-items: center;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}
.nav-logo {
  display: flex;
  flex-direction: column;

}
.locale{
  cursor: pointer;
}
.logo{
  margin-bottom: 10px;
}
.whatsapp-button {
  display: inline-block;
  background-color: #25d366;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}

.menu {
  display: flex;
  gap: 16px;
  align-items: center;
}
.link-span {
  cursor: pointer;
  padding: 5px;
  font-size: 17px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.menu-button {
  display: none;
  background: none;
  border: none;
  margin-right: 10px;
  font-size: 36px;
  cursor: pointer;
}
.active {
  color: #277bcf;
}
.inactive{
  color: black;
}
@media (max-width: 930px) {
  .navbar {
    padding: 5px;
    justify-content: space-between;
  }
  .nav-logo {
    width: 170px;
    text-align: center;
  }
  .menu {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed; /* Fixed to cover the whole screen */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    width: 100%;
    height: 100%; /* Full viewport height */
    z-index: 1000; /* High z-index to be on top */
    box-sizing: border-box;
  }

  .menu.open {
    display: flex; /* Show the menu */
  }

  .menu-button {
    display: block;
  }

  .menu li {
    margin: 20px 0;
  }
}
